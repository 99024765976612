<template>
  <v-navigation-drawer v-model="sidebar" app clipped fixed class="sidebar" :dark="darkTheme" width="280px">
    <v-list :dark="darkTheme">
      <v-list-item to="/" aria-label="Home Sidebar Button">
        <v-list-item-action>
          <v-icon>{{ home.meta.icon }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ home.meta.displayName }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-list v-for="item in items" :key="item.name">
      <template v-if="item.children">
        <v-divider />
        <v-list-group :prepend-icon="item.meta.icon" :value="true">
          <template v-slot:activator>
            <v-list-item-title>{{ item.meta.displayName }}</v-list-item-title>
          </template>
          <v-list-item
            v-for="child in item.children"
            :key="child.name"
            :to="`${item.path}/${child.path}`"
            :aria-label="`${child.meta.displayName} Sidebar Button`"
          >
            <v-list-item-icon />
            <v-list-item-title>{{ child.meta.displayName }}</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-divider />
      </template>

      <template v-else>
        <v-list-item :to="item.path" :aria-label="`${item.meta.displayName} Sidebar Button`">
          <v-list-item-action>
            <v-icon>{{ item.meta.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ item.meta.displayName }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script lang="ts">
import { mapGetters, mapMutations } from 'vuex';
import Vue from 'vue';
import { PrivateRoute } from '@/router/routes';
import ConfirmationBox from '@/components/ui/ConfirmationBox.vue';

export default Vue.extend({
  components: { ConfirmationBox },
  data: () => ({}),
  props: {
    darkTheme: {
      type: Boolean,
      default: true,
    },
  },
  inject: ['routes'],
  computed: {
    home: {
      get(): PrivateRoute {
        return this.routes?.find((route) => route.name === 'home') as Required<PrivateRoute>;
      },
    },
    sidebar: {
      get(): boolean {
        return this.sidebarOpen as unknown as boolean;
      },
      set(val) {
        if (val !== this.sidebarOpen) {
          this.setSidebarOpen(val);
        }
      },
    },
    items() {
      return (this.routes ?? [])
        .filter((route) => route?.meta.sidebar)
        .filter(({ meta }) => {
          return !meta || !meta.isExperimentFeature || this.isExperimentsEnabled;
        })
        .map((route) => {
          if (route.children) {
            return {
              ...route,
              children: route.children.filter((child) => child?.meta?.sidebar !== false),
            };
          }
          return route;
        });
    },
    ...mapGetters(['sidebarOpen']),
    ...mapGetters('tenants', ['isExperimentsEnabled']),
  },
  methods: {
    ...mapMutations(['setSidebarOpen']),
  },
});
</script>

<style>
.v-list {
  padding: 0;
}
.v-list .v-list-item--active {
  color: #ff4069;
}
</style>
