import { render, staticRenderFns } from "./RouterView.vue?vue&type=template&id=6a74a24d"
import script from "./RouterView.vue?vue&type=script&lang=ts"
export * from "./RouterView.vue?vue&type=script&lang=ts"
import style0 from "./RouterView.vue?vue&type=style&index=0&id=6a74a24d&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.9.1_lodash@4.17.21_prettier@2.3.2_vue-template-compiler@2.7.16_webpack@5.94.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports