<template>
  <v-app v-bind:class="sideBarClass()">
    <v-progress-linear
      :indeterminate="true"
      v-if="loadingTheme || !$auth.isAuthenticated"
      id="pageLoader"
      color="grey"
    />
    <template v-if="themeLoaded && $auth.isAuthenticated">
      <template v-if="$auth.user.email">
        <toolbar :logoImage="defaultLogo" />
        <sidebar :dark="true" />
      </template>
      <router-view />
    </template>
  </v-app>
</template>

<script lang="ts">
import Vue from 'vue';

import Sidebar from '@/components/core/Sidebar.vue';
import Toolbar from '@/components/core/Toolbar.vue';
import RouterView from '@/components/core/RouterView.vue';
import { mapGetters, mapActions } from 'vuex';
import { addResponseInterceptor, STATUS_CODES } from '@/services/axios';
import Dropzone from 'dropzone';

Dropzone.autoDiscover = true;

export default Vue.extend({
  async created() {
    await this.loadTenantTheme(this.tenantConfig);

    addResponseInterceptor(
      (config) => {
        if (config.status === STATUS_CODES.UNAUTHORIZED) {
          this.kickToLogin();
        }
        return config;
      },
      (error) => {
        if (error.response) {
          if (error.response.status === STATUS_CODES.UNAUTHORIZED) {
            this.kickToLogin();
          }
        }
        throw error;
      },
      'unauthorized'
    );
  },
  name: 'App',
  components: {
    Sidebar,
    Toolbar,
    RouterView,
  },
  inject: ['dashboardConfig', 'tenantConfig'],
  data() {
    return {
      defaultLogo: require('@/assets/publift-logo.svg'), // Default Logo for Toolbar
    };
  },
  methods: {
    kickToLogin() {
      this.$auth.logout({
        logoutParams: {
          returnTo: `${window.location.origin}/login`,
        },
      });
    },
    sideBarClass() {
      return `sidebar-${this.sidebarOpen ? 'open' : 'closed'}`;
    },
    ...mapActions('tenants', ['loadTenantTheme']),
  },
  computed: {
    ...mapGetters('tenants', ['themeLoaded', 'loadingTheme', 'toolbarConfig', 'sidebarConfig']),
    ...mapGetters(['sidebarOpen']),
  },
});
</script>

<style>
/**
  Prevents the dialog box from losing its shape when its on full screen.
  Without this, the buttons appear outside of the dialog box when on full screen.
  */
.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 85%;
}
/* Required so that the top title is not hidden  */
.v-dialog {
  overflow-y: unset;
}
:root {
  --accent: '#2d2d2d';
  --secondary_accent: '#A0A0A0';
  --tertiary_accent: '#000000';
  --primary: '#ff4069';
  --secondary: '#f16f90';
  --tertiary: '#f9bfce';
}
body,
.body-2,
.body-1,
.caption,
.application {
  font-family: 'Fira Sans', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
.display-4,
.display-3,
.display-2,
.display-1,
.headline,
.title {
  font-family: 'Roboto', sans-serif;
}
.v-main__wrap {
  background-color: #e9e9e9;
}
#pageLoader {
  position: absolute;
  top: -25px;
  left: 0;
}
.card-header {
  padding: 12px;
}
.rounded-card {
  border-radius: 10px;
}
.v-menu__content {
  border-radius: 10px;
}
.ace_gutter {
  z-index: 0 !important;
}
.sidebar {
  z-index: 200;
}
.sidebar-open .v-snack {
  padding-left: 275px;
}

th {
  text-wrap: nowrap !important;
}

#tag-alert > * {
  max-width: 100%;
  width: 95%;
}
</style>
